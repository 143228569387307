<template>
  <section>
    <div class="container">
      <p class="title text-center title-p mb-3">CHECKOUT</p>
      <template v-if="errors.length">
        <div class="alert alert-danger mt-3" v-for="error in errors">
          {{ error }}
        </div>
      </template>
      <div class="checkout__form">
        <form class="checkout-form" @submit.prevent="checkForm">
          <div class="row">
            <div class="col-lg-8 col-md-6">
              <h6 class="checkout__title">Dati personali</h6>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Nome<span>*</span></p>
                    <input type="text" :class="errorField.name ? 'error_field' : ''" class="user_field" v-model="name" name="name">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Cognome<span>*</span></p>
                    <input type="text" :class="errorField.surname ? 'error_field' : ''" class="user_field" v-model="surname" name="surname">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Email<span>*</span></p>
                    <input type="email" :class="errorField.email ? 'error_field' : ''" class="user_field"
                           v-model="email" name="email">
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Altezza in cm<span>*</span></p>
                    <input type="text" :class="errorField.heightcm ? 'error_field' : ''" class="user_field"
                           v-model="heightcm"  name="heightcm">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Circ. seno in cm<span>*</span></p>
                    <input type="text" :class="errorField.breastcm ? 'error_field' : ''" class="user_field"
                           v-model="breastcm"  name="breastcm">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Circ. glutei in cm<span>*</span></p>
                    <input type="text" :class="errorField.sitcm ? 'error_field' : ''" class="user_field" v-model="sitcm"
                           name="sitcm">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Punto vita in cm<span>*</span></p>
                    <input type="text" :class="errorField.waistcm ? 'error_field' : ''" class="user_field"
                           v-model="waistcm" name="waistcm">
                  </div>
                </div>
              </div>
              <h6 class="checkout__title mt-4">Indirizzo di spedizione</h6>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Nome<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.name ? 'error_field' : ''" class="user_field"
                           v-model="shipping.name" name="shipname">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Cognome<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.surname ? 'error_field' : ''" class="user_field"
                           v-model="shipping.surname" name="shipsurname">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="checkout__input">
                    <div class="row">
                      <div class="col-lg-8 col-7">
                        <p class="text font-weight-bolder">Via/piazza<span>*</span></p>
                        <input type="text" name="shipaddress" placeholder="ES: Via Nilo Mondin"
                               class="checkout__input__add user_field"
                               :class="errorFieldShipping.address ? 'error_field' : ''" v-model="shipping.address"
                               v-on:change="changeAddress">
                      </div>
                      <div class="col-lg-4 col-5">
                        <p class="text font-weight-bolder">Civico<span>*</span></p>
                        <input type="text" name="shipnumciv" placeholder="ES: 18"
                               class="checkout__input__add user_field"
                               :class="errorFieldShipping.numciv ? 'error_field' : ''" v-model="shipping.numciv"
                               v-on:change="changeAddress">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="checkout__input mb-4">
                <p class="text font-weight-bolder">Paese<span>*</span></p>
                <select class="form-control" :class="errorFieldShipping.country ? 'error_field' : ''"
                        v-model="shipping.country" v-on:change="saveCountry" name="shipcountry">
                  <option v-for="country in countries" :value="country">
                    {{ country }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Città<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.city ? 'error_field' : ''" class="user_field"
                           v-model="shipping.city" v-on:change="changeAddress" name="shipcity">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Provincia<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.prov ? 'error_field' : ''" class="user_field"
                           v-model="shipping.prov" maxlength="2" v-on:change="changeAddress" name="shipprov">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">CAP<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.cap ? 'error_field' : ''" class="user_field"
                           v-model="shipping.cap" v-on:change="changeAddress" name="shipcap">
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Telefono<span>*</span></p>
                    <input type="text" :class="errorFieldShipping.phone ? 'error_field' : ''" class="user_field"
                           v-model="shipping.phone" name="shipphone">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="checkout__input">
                    <p class="text font-weight-bolder">Note sull'ordine</p>
                    <div class="notes">
                      SCRIVI come ti piacerebbe indossare il capo che hai scelto:
                      <br>
                      -se ti piace indossarlo più slim, normale, o se ti piace portarlo morbido, over etc.
                      <br>
                      -se hai qualcosa del tuo fisico che vuoi valorizzare o camuffare,
                      (es. mi piace un pant super stretto, che metta in mostra le gambe; ho cosce importanti;
                      spalle molto larghe; stomaco molto pronunciato etc.)
                      <br>
                      Così per noi è più facile consigliare la taglia più giusta.
                    </div>
                    <br>
                    <textarea rows="4" class="input_notes mb-4" type="text" v-model="shipping.notes"
                              name="shipnotes"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-6 mt-5" :class="{show:invoice}">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="invoice" value="true" id="fatturazione">
                    <label class="form-check-label" for="fatturazione">
                      <h6 class="checkout__title mb-0">Dati per la fattura</h6>
                      <p class="text text-left">Spuntare se si vuole ricevere la fattura</p>
                    </label>
                  </div>
                </div>
                <div class="col-lg-12 col-md-6 invoice" :class="{show:invoice}">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Azienda</p>
                        <input type="text" class="user_field" v-model="billing.company" name="billingcompany">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Partita IVA</p>
                        <input type="text" class="user_field" v-model="billing.vatnumber" name="billingvat">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">SDI</p>
                        <input type="text" class="user_field" v-model="billing.sdicode" name="billingsdi">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">PEC</p>
                        <input type="text" class="user_field" v-model="billing.pec" name="billingpec">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Telefono</p>
                        <input type="text" class="user_field" v-model="billing.phone" name="billingphone">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Indirizzo (via e numero)</p>
                        <div class="row">
                          <div class="col-lg-8">
                            <input type="text" placeholder="Via/piazza" class="checkout__input__add user_field"
                                   v-model="billing.address" name="billingaddress">
                          </div>
                          <div class="col-lg-4">
                            <input type="text" placeholder="Numero civico" class="checkout__input__add user_field"
                                   v-model="billing.numciv" name="billingnumciv">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="checkout__input mb-4">
                    <p class="text font-weight-bolder">Paese</p>
                    <select class="form-control" id="shipping_country" v-model="billing.country" name="billingcountry">
                      <option v-for="country in countries" :value="country">
                        {{ country }}
                      </option>
                    </select>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Città</p>
                        <input type="text" class="user_field" v-model="billing.city" name="billingcity">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">Provincia</p>
                        <input type="text" class="user_field" v-model="billing.prov" maxlength="2" name="billingprov">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="checkout__input">
                        <p class="text font-weight-bolder">CAP</p>
                        <input type="text" class="user_field" v-model="billing.cap" name="billingcap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="mb-5">
                <h6 class="checkout__title text-center">Codice sconto</h6>
                <div class="input-group">
                  <input type="text" class="sconto form-control" placeholder="Codice sconto" v-model="discountCode"
                         name="discountcode">
                  <button class="btn-coupon" type="button" v-on:click="checkCoupon" v-bind:disabled="check">Applica
                  </button>
                </div>
              </div>
              <div class="checkout__order">
                <h4 class="order__title">Il tuo carrello</h4>
                <div class="font-weight-bolder">PRODOTTI</div>
                <ul class="list-products">
                  <li v-for="cartProduct in cart.lineItems">
                    <b>x{{ cartProduct.qnt }}</b> - {{ cartProduct.product.name }}<br>
                    <div class="d-flex justify-content-between">
                      <div class="mb-3 mt-3 font-weight-bold">
                        <!-- se ho uno sconto con coupon vedo questo-->
                        <template v-if="cartProduct.product.discount">
                          <s>{{ formatPrice(removePriceVAT(cartProduct.product.unitPrice) * cartProduct.qnt) }} €</s> -
                          {{
                            formatPrice(((removePriceVAT(cartProduct.product.unitPrice) * cartProduct.qnt) - cartProduct.product.discount))
                          }}
                          €
                        </template>
                        <template v-else>
                          <!-- se ho uno sconto del prodotto vedo questo-->
                          <template v-if="cartProduct.product.discounted">
                            <s>{{ formatPrice(cartProduct.product.unitPrice * cartProduct.qnt, true) }} €</s> -
                            {{ formatPrice(cartProduct.product.discountPrice * cartProduct.qnt, true) }} €
                          </template>
                          <template v-else>
                            {{ formatPrice(cartProduct.product.unitPrice * cartProduct.qnt, true) }} €
                          </template>
                        </template>
                      </div>
                    </div>
                  </li>
                </ul>
                <hr>
                <p class="text" v-if="this.discountCart"><strong>SUBTOTALE:</strong>
                  <s>{{ formatPrice(parseFloat(this.subtotal) + parseFloat(this.discountCart)) }} €</s> -
                  {{ formatPrice(this.subtotal) }} €</p>
                <p class="text" v-else><strong>SUBTOTALE:</strong> {{ formatPrice(this.subtotal) }} €</p>
                <!-- spese di spedizione -->
                <p class="text" v-if="this.localpickup || this.freeshipping"><strong>SPESE DI SPEDIZIONE:</strong> 0,00
                  € </p>
                <p class="text" v-else><strong> SPESE DI SPEDIZIONE: </strong> {{ formatPrice(this.shippingPrice,false) }} €
                </p>
                <!-- se contrassegno -->
                <p class="text" v-if="this.paymentType === 'cod'"><strong> CONTRASSEGNO: </strong> 5,00 € </p>
                <!-- se sconto -->
                <p class="text" v-if="this.toDiscount"><strong>SCONTO COUPON:</strong> {{formatPrice(this.toDiscount)}} € <br></p>

                <p class="text" v-if="this.paymentType === 'cod'"><strong> TOTALE: {{formatPrice(this.total + 500)}} € </strong></p>

                <p class="text" v-else>
                  <strong>TOTALE: {{formatPrice(this.total)}} €</strong>
                </p>
                <hr>
                <p class="text note">
                  <b>NOTA: È possibile fare il reso della merce entro MAX 15 giorni dal ricevimento del pacco.</b>
                </p>
                <p class="text privacypolicy">
                  I tuoi dati personali saranno utilizzati per elaborare il tuo ordine,
                  supportare la tua esperienza su questo sito web e per altri scopi descritti
                  nella nostra privacy policy.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <h5 class="checkout__title mt-5">Tipo di spedizione</h5>
              <label class="radio-control">
                <input class="radio-button" type="radio" v-bind:value="false" name="shippingType" v-model="localpickup"
                       v-on:change="calculateTotal(); handleCOD();"/> SPEDIZIONE STANDARD (Costo: 9,00 €, gratuito sopra
                i 200,00 €)
              </label>
              <br>
              <label class="radio-control">
                <input class="radio-button" type="radio" v-bind:value="true" name="shippingType" v-model="localpickup"
                       v-on:change="calculateTotal(); handleCOD();"/> RITIRO IN NEGOZIO (Costo: 0,00 €, ritirare la
                merce entro max 10 giorni)
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <h5 class="checkout__title mt-5">Scegli il tuo pagamento</h5>
              <label>
                <input type="radio" value="stripe" name="paymentType" v-model="paymentType"/> PAGA CON CARTA
              </label>
              <br>
              <label>
                <input type="radio" value="bank" name="paymentType" v-model="paymentType"/> PAGA CON BONIFICO
              </label>
              <br>
              <label v-if="cod">
                <input type="radio" value="cod" name="paymentType" v-model="paymentType"/> PAGA CON CONTRASSEGNO ( 5,00
                € AGGIUNTIVI )
              </label>
            </div>
          </div>
          <div class="col d-flex justify-content-center mt-3 mb-5">
            <button type="submit" :disabled="!buttonOrder" class="primary-btn">Effettua ordine</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import {PATH_APP} from "@/constants/constants";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Checkout",
  data() {
    return {
      idorder: null,
      check: false,
      cart: [],
      //usecard: false,
      //usebonifico: false,
      //usecontrassegno: false,
      base: PATH_APP,
      discountedPrice: 0,
      toDiscount: 0,
      discountCode: '',
      paymentType: null,
      shippingPrice: 0,
      totalPrice: 0, //total di partenza
      total: 0, //viene modificato
      subtotal: 0,
      discountCart: 0,
      countries: [
        "Austria",
        "Belgio",
        "Bulgaria",
        "Croazia",
        "Danimarca",
        "Estonia",
        "Finlandia",
        "Francia",
        "Germania",
        "Grecia",
        "Irlanda",
        "Italia",
        "Lettonia",
        "Lituania",
        "Lussemburgo",
        "Paesi Bassi",
        "Polonia",
        "Portogallo",
        "Regno Unito (UK)",
        "Repubblica Ceca",
        "Romania",
        "San Marino",
        "Serbia",
        "Slovacchia",
        "Slovenia",
        "Spagna",
        "Svezia",
        "Svizzera",
        "Ungheria"

      ],
      shipping: {}, // indirizzo di spedizione
      billing: {}, // indirizzo di fatturazione
      /* checkout utente ospite */
      name: null,
      surname: null,
      email: null,
      heightcm: null,
      breastcm: null,
      sitcm: null,
      waistcm: null,
      errors: [],
      errorField: {
        name: false,
        surname: false,
        email: false,
        heightcm: false,
        waistcm: false,
        breastcm: false,
        sitcm: false
      }, //per input rosso
      errorFieldShipping: {
        name: false,
        surname: false,
        address: false,
        numciv: false,
        country: false,
        city: false,
        prov: false,
        cap: false,
        phone: false
      }, //per input rosso
      localpickup: false,
      freeshipping: false,
      invoice: false,
      cod: true, //variabile per fare apparire o scomparire contrassegno
      buttonOrder : true //variabile per disattivare il bottone di checkout
    }
  },
  computed: {
    userCountry(){
      return this.$store.getters.getUserCountry;
    },
    auth() {
      return this.$store.getters.getAuth;
    }
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.name = user.name;
      this.surname = user.surname;
      this.email = user.email;
      this.heightcm = user.heightcm;
      this.breastcm = user.breastcm;
      this.sitcm = user.sitcm;
      this.waistcm = user.waistcm;
      this.shipping.name = user.name;
      this.shipping.surname = user.surname;
      this.shipping.country = user.country;
      this.shipping.address = user.address;
      this.shipping.numciv = user.numciv;
      this.shipping.city = user.city;
      this.shipping.prov = user.prov;
      this.shipping.cap = user.cap;
      this.shipping.phone = user.phone;
    }
  },
  mounted() {
    this.getCart();
    //this.getShippingPrice();
  },
  methods: {
    async getCart() {
      this.$store.commit('showSpinner');
      let idcart = localStorage.getItem("cartIdLaMora");
      if (idcart !== null) {
        let token = localStorage.getItem("token");
        await axios.get("cart?idcart=" + idcart, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.$store.commit('hideSpinner');
          this.cart = resp.data.cart;
          if (this.cart.lineItems.length === 0) {
            //carrello vuoto
            router.push('/');
          } else {
            if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")){
              this.totalPrice = parseFloat(resp.data.cart.totals.total / 1.22).toFixed(2);
              this.total = this.totalPrice;
              this.subtotal = parseFloat(resp.data.cart.totals.subtotal / 1.22).toFixed(2);
              this.discountCart = this.cart.totals.discount;
            }else{
              this.totalPrice = parseFloat(resp.data.cart.totals.total);
              this.total = this.totalPrice;
              this.subtotal = resp.data.cart.totals.subtotal;
              this.discountCart = this.cart.totals.discount;
            }
            //se non è loggato l'utente e non ho settato i campi country e cap metto 9 di default
            if(!this.shipping.country && !this.shipping.cap){
              this.shippingPrice = this.subtotal > 20000 ? 0 : 900;
              this.total = parseFloat(this.total) + parseFloat(this.shippingPrice);
            }
            this.getShippingPrice();
            this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
            if (!localStorage.getItem("cartIdLaMora")) {
              localStorage.setItem("cartIdLaMora", resp.data.cart.id);
            }
            let items = [];
            this.cart.lineItems.forEach((value) => {
              items.push({
                "item_id" : value.idProduct,
                "item_name" : value.product.name,
                "item_brand" : "La Mora Glamour",
                "discount" : 0,
                "currency" : "EUR",
                "price" : Number(value.product.unitPrice / 100),
                "quantity" : value.qnt
              });
            });
            //evento google tag manager
            this.$gtm.trackEvent({
              event: "begin_checkout",
              value: Number(this.total / 100),
              coupon : this.discountCart,
              items : items
            });
          }
        }).catch((error) => {
          this.$store.commit('hideSpinner');
          if (error.response.status === 404) {
            localStorage.removeItem("cartIdLaMora");
            //carrello vuoto
            router.push('/');
          } else {
            Swal.fire({
              title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Riprovare più tardi.",
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#674e42',
            });
          }
        });
      }else{
        router.push("/");
      }
    },
    async checkCoupon() {
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      await axios.post("checkcoupon", {
        "code": this.discountCode,
        "totalcart": this.cart.totals.subtotal.toString(),
        "cart": this.cart
      }, {
        headers: {Authorization: `Bearer ${token}`}
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        if (resp.data.coupon.type === "amount") {
          this.toDiscount = resp.data.coupon.value * 100;
          this.total = this.total - this.toDiscount;//((this.subtotal - this.toDiscount) + this.shippingPrice) < 0 ? 0 : ((this.subtotal - this.toDiscount) + this.shippingPrice);;
          //this.totalPrice = ((this.subtotal - this.toDiscount) + this.shippingPrice) < 0 ? 0 : ((this.subtotal - this.toDiscount) + this.shippingPrice);
        } else if (resp.data.coupon.type === "percentage") {
          //percentuale e quindi sconto tutti i prodotti del carrello tranne quelli presenti dentro excludeProd
          const excludeCats = resp.data.coupon.excludeCat;
          const excludeProds = resp.data.coupon.excludeProd;
          this.subtotal = 0;
          let discount = false;
          for (let i = 0; i < this.cart.lineItems.length; i++) {
            //< 0 significa che non è nell'array
            //se non è scontato faccio controlli per scontare
            discount = false;
            //se non è scontato già il prodotto controllo
            if (!this.cart.lineItems[i]["product"]["discounted"]) {
              //vedo se sono verificate entrambe le condizioni
              if (!excludeCats.length && !excludeProds.length) {
                discount = true;
              } else {
                if (excludeProds.length && excludeProds.indexOf(this.cart.lineItems[i].idProduct) < 0 && excludeCats.length && excludeCats.indexOf(this.cart.lineItems[i].idCategory) < 0) {
                  discount = true;
                } else if (!excludeCats.length && excludeProds.length && excludeProds.indexOf(this.cart.lineItems[i].idProduct) < 0) {
                  discount = true;
                } else if (!excludeProds.length && excludeCats.length && excludeCats.indexOf(this.cart.lineItems[i].idCategory) < 0) {
                  discount = true;
                }
              }
            }
            if (discount) {
              //fare sconto
              let discount = 0;
              if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")) {
                //senza IVA
                //prezzo iniziale - iva
                let priceNotVat = this.cart.lineItems[i]["product"]["unitPrice"] / 1.22;
                let qnt = this.cart.lineItems[i]["qnt"];
                //(prezzosenzaiva * qnt) - (prezzosenzaiva * qnt / 100 * couponvalue)
                discount = parseFloat(((priceNotVat * qnt) / 100) * resp.data.coupon.value);
                //subtotal + priceNotVat * qnt - discount
                this.subtotal = parseFloat(this.subtotal + ((priceNotVat * qnt) - discount));
                this.cart.lineItems[i]["product"]["discount"] = discount;
              }else{
                //con IVA
                discount = parseFloat(((this.cart.lineItems[i]["product"]["unitPrice"] / 100) * parseInt(this.cart.lineItems[i]["qnt"])) * parseFloat(resp.data.coupon.value));
                this.subtotal += parseFloat(this.cart.lineItems[i]["product"]["unitPrice"]) * parseInt(this.cart.lineItems[i]["qnt"]) - parseFloat(discount);
              }
              this.toDiscount += discount;
              //inserisco lo sconto nel carrello
              this.cart.lineItems[i]["product"]["discount"] = discount;
              this.totalPrice = this.totalPrice - discount;
            }else{
              //non c'é lo sconto e quindi rimetto il prezzo
              if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")) {
                //prezzo iniziale - iva
                let priceNotVat = parseFloat(this.cart.lineItems[i]["product"]["unitPrice"] / 1.22);
                this.subtotal += priceNotVat * parseInt(this.cart.lineItems[i]["qnt"]);
              }else {
                this.subtotal += parseFloat(this.cart.lineItems[i]["product"]["unitPrice"]) * parseInt(this.cart.lineItems[i]["qnt"]);
              }
            }
          }
          this.total = (this.subtotal + this.shippingPrice) < 0 ? 0 : (this.subtotal + this.shippingPrice);
        }
        this.check = true;
      }).catch((error) => {
        console.log(error);
        this.$store.commit('hideSpinner');
        if (error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Il coupon inserito non è valido.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        } else if (error.response.status === 401) {
          router.push('/login-checkout');
        }
      });
    },
    async saveCountry(){
      this.$store.commit('showSpinner');
      let token = localStorage.getItem("token");
      //ordine ospite
      if(token !== null) {
        await axios.post("saveusercountry", {
          "country": this.shipping.country
        }, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.$store.commit('hideSpinner');
          //risetto il nuovo utente
          this.$store.commit("SET_USER", resp.data.user);
          localStorage.setItem("user", JSON.stringify(resp.data.user));
          this.getCart();
        }).catch((error) => {
          this.$store.commit('hideSpinner');
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        });
      }else{
        //ordine ospite
        this.$store.commit('hideSpinner');
      }
    },
    async getShippingPrice() {
      this.handleCOD();
      if (this.shipping.country && this.shipping.city && this.shipping.prov && this.shipping.cap
          && this.shipping.address && this.shipping.numciv && !this.localpickup && !this.freeshipping) {
        this.$store.commit('showSpinner');
        let token = localStorage.getItem("token");
        await axios.post("getshippingprice", {
          //"total": this.cart.totals.total,
          "total": this.totalPrice,
          address: {
            "numciv": this.shipping.numciv,
            "address": this.shipping.address,
            "cap": this.shipping.cap,
            "city": this.shipping.city,
            "prov": this.shipping.prov,
            "country": this.shipping.country,
          }
        }, {
          headers: {Authorization: `Bearer ${token}`}
        }).then((resp) => {
          this.$store.commit('hideSpinner');
          this.shippingPrice = resp.data;
          console.log("resp.data",resp.data);
          this.calculateTotal();
        }).catch((error) => {
          this.$store.commit('hideSpinner');
          if (error.response.status === 400) {
            Swal.fire({
              title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#674e42',
            });
          } else {
            Swal.fire({
              title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#674e42',
            });
          }
        });
      }
    },
    changeAddress() {
      this.getShippingPrice();
      this.total = this.totalPrice + this.shippingPrice;
    },
    checkForm() {
      this.errors = [];
      this.errorField = [];
      this.errorFieldShipping = [];

      //CONTROLLO INPUT DEI DATI PERSONALI
      const personalFields = ['name', 'surname', 'email', 'waistcm', 'heightcm', 'sitcm', 'breastcm'];
      for (const fieldName of personalFields) {
        if (!this[fieldName]) {
          this.errorField[fieldName] = true;
        }
      }
      if (personalFields.some(fieldName => this.errorField[fieldName])) {
        window.scrollTo(0, 0);
        this.errors.push('Non hai inserito tutti i tuoi dati personali e le misure nella sezione "DATI PERSONALI".');
      }

      //CONTROLLO INPUT DEI DETTAGLI DI SPEDIZIONE
      const shippingFields = ['name', 'surname', 'country', 'address', 'numciv', 'city', 'prov', 'cap', 'phone'];
      shippingFields.forEach(fieldName => {
        if (!this.shipping[fieldName]) {
          this.errorFieldShipping[fieldName] = true;
        }
      });
      if (shippingFields.some(fieldName => this.errorFieldShipping[fieldName])) {
        window.scrollTo(0, 0);
        this.errors.push('Non hai inserito uno o più campi obbligatori nella sezione "INDIRIZZO DI SPEDIZIONE".');
      }

      //CONTROLLO MISURE IN CM
      this.validateField('Punto vita', this.waistcm, 'waistcm');
      this.validateField('Altezza', this.heightcm, 'heightcm');
      this.validateField('Circ. seno', this.breastcm, 'breastcm');
      this.validateField('Circ. glutei', this.sitcm, 'sitcm');

      //CONTROLLO SELEZIONE METODO DI PAGAMENTO
      if (!this.paymentType) {
        window.scrollTo(0, 0);
        this.errors.push('Devi selezionare un metodo di pagamento nella sezione "SCEGLI IL TUO PAGAMENTO".');
      }
      if (this.shipping.prov.length !== 2) {
        this.errorFieldShipping.prov = true;
        window.scrollTo(0, 0);
        this.errors.push('Il campo "Provincia" deve contenere 2 lettere.');
      }
      if (this.shipping.cap.length !== 5 && this.shipping.cap.length !== 4) {
        this.errorFieldShipping.cap = true;
        window.scrollTo(0, 0);
        this.errors.push('Il campo "CAP" deve contenere 5 cifre.');
      }
      if (this.errors.length === 0) {
        this.placeOrder();
      }
    },
    validateSize(sizecm) { //trasforma gli input con la virgola
      return sizecm.toString().replace(",", ".");
    },
    validateField(fieldName, fieldValue, field) {
      if (!/^-?\d+(?:[,.]\d*?)?$/.test(fieldValue)) {
        this.errorField[field] = true;
        window.scrollTo(0, 0);
        this.errors.push(`Il campo "${fieldName}" è vuoto o non ha un formato valido.`);
      } else {
        this.errorField[field] = false;
      }
    },
    async placeOrder() {
      this.$store.commit('showSpinner');
      this.buttonOrder = false;
      let token = localStorage.getItem("token");
      await axios.post("checkout", {
        localpickup: this.localpickup,
        name: this.name,
        surname: this.surname,
        email: this.email,
        paymentType: this.paymentType,
        notes: this.shipping.notes,
        discountCode: this.discountCode,
        shipping: this.shipping,
        billing: this.billing,
        waistcm: this.validateSize(this.waistcm),
        heightcm: this.validateSize(this.heightcm),
        sitcm: this.validateSize(this.sitcm),
        breastcm: this.validateSize(this.breastcm),
        idcart: localStorage.getItem("cartIdLaMora"),
        //cod: this.usecontrassegno,
      }, {
        headers: {Authorization: `Bearer ${token}`}
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        this.idorder = resp.data.idorder;
        //purchase_order event
        let items = [];
        this.cart.lineItems.forEach((value) => {
          items.push({
            "item_id" : value.idProduct,
            "item_name" : value.product.name,
            "item_brand" : "La Mora Glamour",
            "discount" : 0,
            "currency" : "EUR",
            "price" : Number(value.product.unitPrice / 100),
            "quantity" : value.qnt
          });
        });
        this.$gtm.trackEvent({
          event: "purchase",
          transaction_id : this.idorder,
          value: Number(this.total / 100),
          tax: 0,
          shipping: Number(this.shippingPrice / 100),
          currency: "EUR",
          coupon : "",
          items: items
        });
        if (this.paymentType === 'stripe') {
          router.push("/payment/" + this.idorder);
        } else if (this.paymentType === 'bank') {
          //purchase
          this.emptyCart();
          this.confirmOrder("/checkout/bank/" + this.idorder);
          router.push("/message/success");
        } else if (this.paymentType === 'cod') {
          //purchase
          this.emptyCart();
          this.confirmOrder("/checkout/cod/" + this.idorder);
          router.push("/message/success");
        }
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        this.buttonOrder = true;
        if (error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        } else {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        }
      });
    },
    async emptyCart() {
      let idcart = localStorage.getItem("cartIdLaMora");
      if (idcart !== null) {
        let token = localStorage.getItem("token");
        await axios.delete("emptycart", {
          headers: {Authorization: `Bearer ${token}`},
          data: {
            idCart: idcart,
          }
        }).then((resp) => {
          this.cart = resp.data.cart;
          this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
        }).catch((error) => {
          this.$store.commit('hideSpinner');
          if (error.response.status === 400) {
            Swal.fire({
              title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#674e42',
            });
          } else {
            Swal.fire({
              title: error.response.data.error ? error.response.data.error : "Qualcosa è andato storto. Controlla i dati o riprova più tardi.",
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#674e42',
            });
          }
        });
      }
    },
    async confirmOrder(url) {
      let token = localStorage.getItem("token");
      await axios.get(url, {
        headers: {Authorization: `Bearer ${token}`}
      }).then((resp) => {
        console.log(resp);
      }).catch((error) => {
        console.log(error);
      });
    },
    formatPrice(value,removeVat = false) {
      if(removeVat && this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")) {
        value = this.removePriceVAT(value);
      }
      return Number(value / 100)
          .toFixed(2)
          .replace(".", ",");
    },
    removePriceVAT(value){
      if(this.userCountry && (this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)")) {
        //value = (value - ((value / 100) * IVA)).toFixed(2);
        value = (value / 1.22).toFixed(2);
      }
      return value;
    },
    calculateTotal() {
      if (!this.localpickup && !this.freeshipping) { //se spedizione standard e sotto i 200
        this.total = this.totalPrice + this.shippingPrice;
      } else {
        this.total = this.totalPrice;
      }
    },
    handleCOD() {
      //contrassegno va tolto anche se scegli ritiro in sede
      if (this.localpickup || this.userCountry === "Svizzera" || this.userCountry === "Regno Unito (UK)") {
        this.cod = false;
        this.paymentType = null;
      } else {
        this.cod = true;
      }
    }
  },
}
</script>

<style scoped>

.error_field { /* quando un campo è vuoto */
  border: 1px solid red !important;
}

.notes {
  font-family: opensans;
  font-size: 14px;
}

textarea {
  width: 100%;
  border: 1px solid #e1e1e1;
}


.btn-coupon {
  border: none;
  background-color: black;
  color: white;
  padding: 7px 20px 7px 20px;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: opensans-light;
}

.btn-coupon:disabled {
  background-color: grey;
}

.sconto {
  border: 1px solid #e1e1e1;
  padding: 5px;
}

.user_field {
  font-family: opensans;
  font-size: 16px;
  margin-bottom: 15px;
}

.invoice {
  margin-top: 50px;
  display: none;
}

.show {
  display: block !important;
}

.list-products {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.list-products li {
  width: 100%;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}

.privacypolicy {
  font-size: 12px;
  line-height: 15px;
}

.note {
  font-size: 15px;
  line-height: 18px;
}
</style>